import { Component } from 'react';
import PropTypes from 'prop-types';

import Optional from '../utils/optional';

const STORE_KEY = 'store';

export default function promiseActions () {
  return WrappedInstance => {
    class PromiseActions extends Component {
      constructor (props, context) {
        super(props);

        this.store = Optional(props[STORE_KEY]).or(context[STORE_KEY]);

        this.promise = (...args) => new Promise((resolve, reject) => {
          this.props.start(...args);
          this.resolve = resolve;
          this.reject = reject;
          this.subscribe();
        });
      }
      componentDidMount () {
        this.props.start();
      };
      componentWillUnmount () {
        this.unsubscribe && this.unsubscribe();
      }
      subscribe = () => {
        this.unsubscribe = this.store.subscribe(() => {
          const { type } = this.store.getState().promiseAction;
          if (type === this.props.resolve) {
            this.resolve();
            this.unsubscribe();
          }
          if (type === this.props.reject) {
            this.reject();
            this.unsubscribe();
          }
        });
      }
      render () {
        return (
          <WrappedInstance {...this.props} promise={this.promise} />
        );
      }
    }

    PromiseActions.contextTypes = { [STORE_KEY]: PropTypes.object };

    return PromiseActions;
  };
}

export function promiseActionReducer (state = {}, action) {
  return { ...action };
}

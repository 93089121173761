export const URLS = {
  // uaa
  GET_SESSION: '/api/user',
  // dictionaries
  DICTIONARIES_ENTRIES: '/dictionaries/api/dictionary-entries',
  LOGOUT: '/api/logout',
  GET_SECURITY_DETAILS: '/api/user/securityDetails',
  IS_AUTHENTICATED: '/api/isAuthenticated'
};

export const DICT_TYPE = {
  SALES_CASE_REASON_WON: 'sales-case-reason-won',
  SALES_CASE_REASON_LOST: 'sales-case-reason-lost',
  GROUPS: 'groups',
  UI_TYPE: 'ui',
  TASKS_PRIORITY: 'task-priority',
  MEASURE_UNIT: 'measureunit',
  OFFER_TYPE: 'offer-type',
  SALES_PIPELINE_STATE: 'sales-pipeline-states',
  FIBER_ADDRESSES: 'fiber-favourites-addresses',
  FIBER_CONTRACT_LENGTHS: 'fiber-contract-lengths',
  CONNECTORS: 'activiti-connectors',
  PRODUCT_CATEGORIES: 'product-categories',
  PRESALER: 'presaler'
};

export const STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  CONFLICT: 409,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_ERROR: 500
};

export const getDictionaryEntries = type => `${URLS.DICTIONARIES}/${type}/entries`;

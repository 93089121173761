import axios from 'axios';

import { NAV_URLS, getStatusPageUrl } from './navigation-service';
import { setLogOut, setInitialized } from '../auth/reducers';
import Optional from './optional';
import history from '../history';

const TIMEOUT = 1000000;
const setupAxiosInterceptors = dispatch => {
  const onRequestSuccess = config => {
    config.timeout = TIMEOUT;
    return config;
  };
  const onResponseSuccess = response => {
    const status = response.status || response.response.status;
    return { ...response, status };
  };
  const onResponseError = err => {
    const status = err.status || Optional(err.response).map(res => res.status).or('');
    if (status === 403) {
      history.push(getStatusPageUrl(status));
    }
    if (status === 401) {
      dispatch(setLogOut());
      dispatch(setInitialized());
      window.location.assign(NAV_URLS.LOGIN_PAGE);
    }
    return Promise.reject({ ...err, status });
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

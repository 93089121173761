import { createReduxActionTypes } from '../../../utils/action-factory';

const NAV_BAR_MENU_TOOGLE = 'NAV_BAR_MENU_TOOGLE';
const NAV_BAR_MENU_CLOSE = 'NAV_BAR_MENU_CLOSE';
const NAV_BAR_MENU_OPEN = 'NAV_BAR_MENU_OPEN';
const MINIMIZE_MENU = 'MINIMIZE_MENU';
const MAXIMIZE_MENU = 'MAXIMIZE_MENU';
const TOGGLE_MENU = 'TOGGLE_MENU';

const actions = createReduxActionTypes('APP_NAVIGATION_BAR', [
  NAV_BAR_MENU_TOOGLE,
  NAV_BAR_MENU_CLOSE,
  NAV_BAR_MENU_OPEN,
  MINIMIZE_MENU,
  MAXIMIZE_MENU,
  TOGGLE_MENU
]);

export const toggle = () => ({ type: actions[NAV_BAR_MENU_TOOGLE] });
export const close = () => ({ type: actions[NAV_BAR_MENU_CLOSE] });
export const open = () => ({ type: actions[NAV_BAR_MENU_OPEN] });
export const minimizeMenu = () => ({ type: actions[MINIMIZE_MENU] });
export const maximizeMenu = () => ({ type: actions[MAXIMIZE_MENU] });
export const toggleMenuSize = () => ({ type: actions[TOGGLE_MENU] });

const DEFAULT_STATE = {
  on: false,
  isMenuMinimized: false
};

export default function navigationBar (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions[NAV_BAR_MENU_TOOGLE]:
      return {
        ...state,
        on: !state.on
      };

    case actions[NAV_BAR_MENU_CLOSE]:
      return {
        ...state,
        on: false
      };

    case actions[NAV_BAR_MENU_OPEN]:
      return {
        ...state,
        on: true
      };

    case actions[MINIMIZE_MENU]:
      return {
        ...state,
        isMenuMinimized: true
      };

    case actions[MAXIMIZE_MENU]:
      return {
        ...state,
        isMenuMinimized: false
      };

    case actions[TOGGLE_MENU]:
      return {
        ...state,
        isMenuMinimized: !state.isMenuMinimized
      };

    default:
      return state;
  }
}

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import da from 'react-intl/locale-data/da';

import { isString, isUndefined } from './is-defined';
import { DEFAULT_LANGUAGE } from '../i18n/helpers';
import messages from '../i18n/translations.json';

let getState;

export default function translate (messageDescriptor) {
  if (isUndefined(messageDescriptor)) {
    return;
  }
  if (process.env.NODE_ENV === 'test') {
    return getTranslation(messageDescriptor, DEFAULT_LANGUAGE);
  }
  return getTranslation(messageDescriptor, getLanguage());
}

export function setupTranslate (store) {
  addLocaleData([...en, ...da]);
  getState = store.getState;
}

function getLanguage () {
  try {
    return getState().personalSettings.userLanguage;
  } catch (e) {
    console.warn('Store is not configured, fallback to english', e);
    return DEFAULT_LANGUAGE;
  }
}

function getTranslation (messageDescriptor, language) {
  if (isString(messageDescriptor)) {
    console.warn('Using string instead of message descriptor for: ', messageDescriptor);
    return messageDescriptor;
  }
  const translation = messages[language][messageDescriptor.id];
  if (!translation) {
    process.env.NODE_ENV !== 'test' && console.warn('Missing translation for: ', messageDescriptor.id);
  }
  return translation || messageDescriptor.defaultMessage;
}

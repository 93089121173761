import { Component } from 'react';

import Optional from './optional';

export default function localStorageHoc (key, mapStorageToProps) {
  return WrappedInstance => class LocalStorage extends Component {
    render () {
      return (
        <WrappedInstance
          {...this.props}
          {...getFromStorage(key, mapStorageToProps)}
          save={data => saveToStorage(key, data)}
        />
      );
    }
  };
}

export function getFromStorage (key, mapStorageToProps = data => data) {
  const data = localStorage.getItem(getKey(key));
  return Optional(data)
    .map(JSON.parse)
    .map(mapStorageToProps)
    .or({});
}

export function saveToStorage (key, data) {
  localStorage.setItem(getKey(key), JSON.stringify(data));
}

function getKey (key) {
  return `${process.appName}-${key}`;
}

import avatarPlaceholder from '../../../assets/avatar_placeholder.png';
import { isStringDefined } from '../../utils/is-defined';
import Optional from '../../utils/optional';

export default function Avatar ({ name, className, firstName = '', lastName = '', imageUrl = '' }) {
  const imgUrl = isStringDefined(imageUrl) ? imageUrl : avatarPlaceholder;
  return (
    <img
      className={`common-avatar ${className}`}
      src={imgUrl}
      alt={Optional(name).or(`${firstName} ${lastName}`)}/>
  );
}

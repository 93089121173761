import { Switch, Route, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import PrivateRoute from '../common/private-route/private-route';
import ErrorBoundary from '../common/error-boundary/error-boundary';
import { NAV_URLS } from '../utils/navigation-service';
import messages from '../i18n/translations.json';

import App from '../app/app';
import ApiErrorDialog from '../api-error-dialog/api-error-dialog';
import LoginRedirect from './login-redirect';

function Routes ({ initialized }) {
  const localizedMessages = messages["en"];
  return initialized ? (
    <IntlProvider locale="en" messages={localizedMessages}>
      <ErrorBoundary>
        <Switch>
          <PrivateRoute path={NAV_URLS.HOME} component={App} />
          <Route path={NAV_URLS.LOGIN_PAGE} component={LoginRedirect} />
          <Redirect exact from="/" to={NAV_URLS.HOME} />
        </Switch>
        <ToastContainer
          autoClose={3000}
          position="bottom-right"
          hideProgressBar
          transition={Slide}
        />
        <ApiErrorDialog />
      </ErrorBoundary>
    </IntlProvider>
  ) : null;
}

export default withRouter(
  connect(({ auth }) => ({
    initialized: auth.initialized
  }))(Routes)
);

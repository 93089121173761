import { all } from 'redux-saga/effects';

import auth from '../auth/actions';
import dictionaries from '../dictionaries/with-dictionary/actions';

export default function* rootSaga () {
  try {
    yield all([
      ...auth,
      dictionaries
    ]);
  } catch (e) {
    console.warn('Saga error:', e); 
  }
}

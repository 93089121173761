import { connect } from 'react-redux';
import { lazy, Suspense, useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Switch, Route, Redirect } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

import { saveToStorage, getFromStorage } from 'utils/local-storage';
import { isDefined } from 'utils/is-defined';
import { REDIRECT_URL } from '../routing/login-redirect';

import { NAV_URLS } from '../utils/navigation-service';

import NavigationBar from './navigation-bar/navigation-bar';
import AppLoader from './app-loader/app-loader';
import Breadcrumbs from '../common/breadcrumbs';

const NoRoute = lazy(() => import(/* webpackChunkName: "NoRoute" */ '../no-route/no-route'));

function App ({ isMenuMinimized, location }) {
  useEffect(() => {
    const redirectURL = getFromStorage(REDIRECT_URL);
    if (isDefined(redirectURL)) {
      saveToStorage(REDIRECT_URL, null);
      location.href = `${location.origin}${redirectURL}`;
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={`app ${isMenuMinimized ? 'minimized-menu' : ''}`}>
          <NavigationBar />
          <main className="app-content">
            <AppLoader />
            <div className="app-breadcrumbs-container">
              <Breadcrumbs />
            </div>
            <Suspense fallback={<CircularProgress className="route-lazy-load-progress" size={80} />}>
              <Switch>
                <Redirect exact from={NAV_URLS.HOME} to={NAV_URLS.SWAGGER} />
                <Route exact path={NAV_URLS.SWAGGER} component={Swagger} />
                <Route component={NoRoute} />
              </Switch>
            </Suspense>
          </main>
        </div>
    </MuiPickersUtilsProvider>
  );
}

function Swagger(){
  return (
    <div>
      <iframe title="swagger" src={`${window.location.origin}/swagger-ui/index.html`} style={{
        border: 'none',
        width: '100%',
        height: '100%',
        minHeight: 'calc(100vh - 35px)'
      }}/>
    </div>
  );
}

export default connect(state => ({
  isMenuMinimized: state.navigationBar.isMenuMinimized
}))(App);

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  delete: { id: 'app.common.delete', defaultMessage: 'Delete' },
  save: { id: 'app.common.save', defaultMessage: 'Save' },
  edit: { id: 'app.common.edit', defaultMessage: 'Edit' },
  create: { id: 'app.common.create', defaultMessage: 'Create' },
  remove: { id: 'app.common.remove', defaultMessage: 'Remove' },
  name: { id: 'app.common.name', defaultMessage: 'Name' },
  firstName: { id: 'app.common.firstName', defaultMessage: 'First name' },
  lastName: { id: 'app.common.lastName', defaultMessage: 'Last name' },
  sku: { id: 'app.common.sku', defaultMessage: 'SKU' },
  initialPrice: { id: 'app.common.initialPrice', defaultMessage: 'Initial price' },
  initialProfit: { id: 'app.common.initialProfit', defaultMessage: 'Initial profit' },
  initialCost: { id: 'app.common.initialCost', defaultMessage: 'Initial cost' },
  recurringPrice: { id: 'app.common.recurringPrice', defaultMessage: 'Recurring price' },
  recurringProfit: { id: 'app.common.recurringProfit', defaultMessage: 'Recurring profit' },
  profit: { id: 'Profit', defaultMessage: 'Profit' },
  revenue: { id: 'Revenue', defaultMessage: 'Revenue' },
  recurringCost: { id: 'app.common.recurringCost', defaultMessage: 'Recurring cost' },
  comission: { id: 'app.common.comission', defaultMessage: 'Commission' },
  description: { id: 'app.common.description', defaultMessage: 'Description' },
  offerProd: { id: 'app.common.offerProd', defaultMessage: 'Offer Product' },
  category: { id: 'app.common.category', defaultMessage: 'Category' },
  internalDescription: { id: 'app.common.internalDescription', defaultMessage: 'Internal description' },
  minQty: { id: 'app.common.minQty', defaultMessage: 'Min quantity' },
  maxQty: { id: 'app.common.maxQty', defaultMessage: 'Max quantity' },
  qty: { id: 'Quantity', defaultMessage: 'Quantity' },
  price: { id: 'app.common.price', defaultMessage: 'Price' },
  initial: { id: 'app.common.initial', defaultMessage: 'Initial' },
  catalog: { id: 'app.common.catalog', defaultMessage: 'Catalog' },
  cost: { id: 'app.common.cost', defaultMessage: 'Cost' },
  discount: { id: 'app.common.discount', defaultMessage: 'Discount' },
  recurring: { id: 'app.common.recurring', defaultMessage: 'Recurring' },
  type: { id: 'app.common.type', defaultMessage: 'Type' },
  status: { id: 'app.common.status', defaultMessage: 'Status' },
  customer: { id: 'app.common.customer', defaultMessage: 'Customer' },
  cancel: { id: 'app.common.cancel', defaultMessage: 'Cancel' },
  addr1: { id: 'app.common.addr1', defaultMessage: 'Address line 1' },
  addr2: { id: 'app.common.addr2', defaultMessage: 'Address line 2' },
  zipCode: { id: 'app.common.zipCode', defaultMessage: 'Zip Code' },
  city: { id: 'app.common.city', defaultMessage: 'City' },
  country: { id: 'app.common.country', defaultMessage: 'Country' },
  yes: { id: 'app.common.yes', defaultMessage: 'Yes' },
  no: { id: 'app.common.no', defaultMessage: 'No' },
  continue: { id: 'app.common.continue', defaultMessage: 'Continue?' },
  accNumber: { id: 'app.common.accNumber', defaultMessage: 'Account number' },
  companyRegNo: { id: 'app.common.companyRegNo', defaultMessage: 'VAT Number' },
  account: { id: 'Account', defaultMessage: 'Account' },
  noAccounts: { id: 'app.common.noAccounts', defaultMessage: 'There are no accounts.' },
  loading: { id: 'app.common.loading', defaultMessage: 'Loading' },
  contactPerson: { id: 'app.common.contactPerson', defaultMessage: 'Contact person' },
  email: { id: 'app.common.email', defaultMessage: 'Email' },
  tel: { id: 'app.common.tel', defaultMessage: 'Telephone' },
  company: { id: 'app.common.company', defaultMessage: 'Company' },
  sortBy: { id: 'app.common.sortBy', defaultMessage: 'Sort by' },
  addProduct: { id: 'app.common.addProduct', defaultMessage: 'Add product' },
  unsavedFormWarn: { id: 'app.common.unsavedFormWarn', defaultMessage: 'You have unsaved changes, are you sure want to leave?' },
  noItems: { id: 'app.common.noItems', defaultMessage: 'No items found' },
  all: { id: 'app.common.all', defaultMessage: 'All' },
  createdBy: { id: 'app.common.createdBy', defaultMessage: 'Created by' },
  generation: { id: 'app.common.generation', defaultMessage: 'Generation' },
  validUntil: { id: 'app.common.validUntil', defaultMessage: 'Valid until' },
  salesPipeline: { id: 'app.common.salesPipeline', defaultMessage: 'Sales pipeline' },
  salesRep: { id: 'Sales rep.', defaultMessage: 'Sales rep.' },
  age: { id: 'app.common.age', defaultMessage: 'Age' },
  products: { id: 'app.common.products', defaultMessage: 'Products' },
  offers: { id: 'Offers', defaultMessage: 'Offers' },
  initialOfferedPrice: { id: 'app.common.initialOfferedPrice', defaultMessage: 'Initial offered price' },
  recurringOfferedPrice: { id: 'app.common.recurringOfferedPrice', defaultMessage: 'Recurring offered price' },
  catalogInitialPrice: { id: 'app.offeringDetails.components.form.catalogInitialPrice', defaultMessage: 'Catalog initial price' },
  catalogRecurringPrice: { id: 'app.offeringDetails.components.form.catalogRecurringPrice', defaultMessage: 'Catalog recurring price' },
  costInitialPrice: { id: 'app.offeringDetails.components.form.costInitialPrice', defaultMessage: 'Cost initial price' },
  costRecurringPrice: { id: 'app.offeringDetails.components.form.costRecurringPrice', defaultMessage: 'Cost recurring price' },
  fixedProfit: { id: 'app.offeringDetails.components.form.fixed.profit', defaultMessage: 'Fixed profit' },
  contractLength: { id: 'app.common.contractLength', defaultMessage: 'Contract length' },
  months: { id: 'app.common.months', defaultMessage: '{monthCount} months' },
  street: { id: 'ipv.common.street', defaultMessage: 'Street' },
  streetNumber: { id: 'ipv.common.streetNumber', defaultMessage: 'Street number' },
  floor: { id: 'ipv.common.floor', defaultMessage: 'Floor' },
  door: { id: 'ipv.common.door', defaultMessage: 'Door number' },
  comment: { id: 'Comment', defaultMessage: 'Comment' },
  commentPlaceholder: { id: 'Type your comment', defaultMessage: 'Type your comment' },
  addComment: { id: 'Add comment', defaultMessage: 'Add comment' },
  search: { id: 'Search', defaultMessage: 'Search' },
  searchInSuperOffice: { id: 'Search in Super Office...', defaultMessage: 'Search in Super Office...' },
  addFromSupperOffice: { id: 'Add from Super Office', defaultMessage: 'Add from Super Office' },
  primary: { id: 'app.common.primary', defaultMessage: 'Primary' },
  close: { id: 'Close', defaultMessage: 'Close' },
  assignedTo: { id: 'Assigned to', defaultMessage: 'Assigned to' },
  assignedRole: { id: 'Role', defaultMessage: 'Role' },
  start: { id: 'Start', defaultMessage: 'Start' },
  end: { id: 'End', defaultMessage: 'End' },
  deadline: { id: 'Deadline', defaultMessage: 'Deadline' },
  expCloseDate: { id: 'Exp. close date', defaultMessage: 'Exp. close date' },
  comfirmRemove: { id: 'The item will we removed permanently', defaultMessage: 'The item will we removed permanently' },
  loadMore: { id: 'Load more', defaultMessage: 'Load more' },
  noResults: { id: 'No results', defaultMessage: 'No results' },
  salecases: { id: 'Sales cases', defaultMessage: 'Sales cases' },
  variant: { id: 'Variant', defaultMessage: 'Variant' },
  messages: { id: 'Messages', defaultMessage: 'Messages' },
  author: { id: 'Author', defaultMessage: 'Author' },
  history: { id: 'History', defaultMessage: 'History' },
  comments: { id: 'Comments', defaultMessage: 'Comments' },
  notApplicable: { id: 'n/a', defaultMessage: 'n/a' },
  owner: { id: 'Owner', defaultMessage: 'Owner' },
  IN_PROGRESS: { id: 'In progress', defaultMessage: 'In progress' },
  COMPLETED: { id: 'Completed', defaultMessage: 'Completed' },
  PENDING: { id: 'Pending', defaultMessage: 'Pending' },
  pipelineState: { id: 'Pipeline state', defaultMessage: 'Pipeline state' },
  providerResponseTitle: { id: 'ipv.fiberWizard.default.fiber.component.provider.response.title', defaultMessage: 'Provider response' },
  providerResponseSwitchLabel: { id: 'ipv.fiberWizard.default.fiber.component.provider.response.switch', defaultMessage: 'Show provider response' },
  additionalRequestOptions: { id: 'app.ipvision.fiberWizard.additional.request.optiona.label', defaultMessage: 'Additional request options' },
  showRequestOptions: { id: 'ipv.fiberWizard.default.fiber.request.options.labek', defaultMessage: 'Show request options' },
  noProvidersAvailable: { id: 'ipv.fiberWizard.noVendors', defaultMessage: 'Sorry, no fiber providers are available' },
  next: { id: 'Next', defaultMessage: 'Next' },
  previous: { id: 'Previous', defaultMessage: 'Previous' },
  back: { id: 'Back', defaultMessage: 'Back' },
  finish: { id: 'ipv.fiberWizard.finish', defaultMessage: 'Finish' },
  favAddress: { id: 'app.ipvision.fiberWizard.favAddress', defaultMessage: 'Favourite address' },
  to: { id: 'app.ipvision.fiberWizard.to', defaultMessage: 'To' },
  from: { id: 'app.ipvision.fiberWizard.from', defaultMessage: 'From' },
  unableToDeliver: { id: 'ipv.fiberWizard.unableToDeliver', defaultMessage: 'The vendor is unable to provide a fiber-optic connection to the address provided.' },
  selected: { id: 'ipv.fiberWizard.selected', defaultMessage: 'Selected' },
  selectFromExistingProductSections: { id: 'ipv.fiberWizard.selectExistingSection', defaultMessage: 'Select from existing product sections:' },
  sectionName: { id: 'ipv.fiberWizard.sectionName', defaultMessage: 'New section name' },
  sectionNameError: { id: 'ipv.fiberWizard.sectionNameError', defaultMessage: `Section name can't be the same as existing one` },
  selectedSection: { id: 'ipv.fiberWizard.selectedSection', defaultMessage: 'Selected section' },
  speed: { id: 'ipv.fiberWizard.speed', defaultMessage: 'Speed: {speed} Mbps' },
  noNameSection: { id: 'ipv.fiberWizard.noNameSection', defaultMessage: 'Section no name' },
  matchingAddress: { id: 'Found the matching address', defaultMessage: 'Found the matching address' },
  verifyWithAddres: { id: 'Verify using this address', defaultMessage: 'Verify using this address' },
  selectFiber: { id: 'ipv.fiberWizard.selectFiber', defaultMessage: 'Select fiber' },
  selectProduct: { id: 'ipv.fiberWizard.selectProduct', defaultMessage: 'Select product' },
  collectAddress: { id: 'ipv.fiberWizard.collectAddress', defaultMessage: 'Address' },
  selectSection: { id: 'ipv.fiberWizard.selectSection', defaultMessage: 'Select product section' },
  summary: { id: 'ipv.fiberWizard.summary', defaultMessage: 'Summary' },
  orderNo: { id: 'app.order.number', defaultMessage: 'Number' },
  createdByUser: { id: 'app.order.createdByUser', defaultMessage: 'Created by' },
  assignedUser: { id: 'app.order.assignedUser', defaultMessage: 'Assigned to' },
  state: { id: 'app.order.state', defaultMessage: 'Status' },
  orderDate: { id: 'app.order.orderDate', defaultMessage: 'Order date' },
  customerContactPerson: { id: 'app.order.customerContactPerson', defaultMessage: 'Customer contact person' },
  FULFILLED: { id: 'Fulfilled', defaultMessage: 'Fulfilled' },
  CANCELLED: { id: 'Cancelled', defaultMessage: 'Cancelled' },
  addOrder: { id: 'app.order.add', defaultMessage: 'Add order' },
  orderLine: { id: 'Order line', defaultMessage: 'Order line' },
  expectedDelivery: { id: 'Expected Delivery', defaultMessage: 'Expected Delivery' },
  noOrderLines: { id: 'There are no order lines', defaultMessage: 'There are no order lines' },
  removeDelivery: { id: 'Remove delivery', defaultMessage: 'Remove delivery' },
  removeOrderLine: { id: 'Remove order line', defaultMessage: 'Remove order line' },
  addOrderLine: { id: 'Add order line', defaultMessage: 'Add order line' },
  addDelivery: { id: 'Add delivery', defaultMessage: 'Add delivery' },
  order: { id: 'Order', defaultMessage: 'Order' },
  delivery: { id: 'Delivery', defaultMessage: 'Delivery' },
  orderAddSuccess: { id: 'app.order.actions.add.success', defaultMessage: 'Order successfully added!' },
  orderAddFail: { id: 'app.order.actions.add.fail', defaultMessage: 'Sorry, adding an order failed' },
  conflictDeliveryMsg: { id: 'Delivery which you try to update has changed. Refresh order to get updated values.', defaultMessage: 'Delivery which you try to update has changed. Refresh order to get updated values.' },
  orderEditSuccess: { id: 'app.order.actions.edit.success', defaultMessage: 'Order successfully edited!' },
  conflictOrderMsg: { id: 'Order which you try to update has changed. Refresh order to get updated values.', defaultMessage: 'Order which you try to update has changed. Refresh order to get updated values.' },
  orderEditFail: { id: 'app.order.actions.edit.fail', defaultMessage: 'Sorry, editing an order failed' },
  conflictOrderLineMsg: { id: 'Order line which you try to update has changed. Refresh order to get updated values.', defaultMessage: 'Order line which you try to update has changed. Refresh order to get updated values.' },
  offerID: { id: 'app.order.offer.details.box.offerID', defaultMessage: 'Offer ID' },
  overview: { id: 'app.order.overview', defaultMessage: 'Overview' },
  refreshAccountInfo: { id: 'Account details will be updated according to data from Super Office.', defaultMessage: 'Account details will be updated according to data from Super Office.' },
  refreshAccountError: { id: 'There was a problem downloading data from Super Office. Probably account was deleted.', defaultMessage: 'There was a problem downloading data from Super Office. Probably account was deleted.' },
  refreshFailed: { id: 'Refresh failed!', defaultMessage: 'Refresh failed!' },
  proceed: { id: 'Proceed', defaultMessage: 'Proceed' },
  successMsgAddAccount: { id: 'app.accounts.successMsgAdd', defaultMessage: 'Account successfully created!' },
  failMsgAddAccount: { id: 'app.accounts.failMsgAdd', defaultMessage: 'Sorry, account creation failed' },
  successMsgEditAccount: { id: 'app.accounts.successMsgEdit', defaultMessage: 'Account successfully saved!' },
  failMsgEditAccount: { id: 'app.accounts.failMsgEdit', defaultMessage: 'Sorry, saving account failed' },
  contacts: { id: 'app.accounts.contacts', defaultMessage: 'Contacts' },
  salesCases: { id: 'app.accounts.salesCases', defaultMessage: 'Sales cases' },
  orders: { id: 'app.accounts.orders', defaultMessage: 'Orders' },
  goToSaleCase: { id: 'app.accounts.goToSaleCase', defaultMessage: 'Go to sale case' },
  expectedCloseDate: { id: 'app.accounts.expectedCloseDate', defaultMessage: 'Exp. close date' },
  subscriptions: { id: 'app.accounts.subscriptions', defaultMessage: 'Subscriptions' },
  dateUpdated: { id: 'app.accounts.dateUpdated', defaultMessage: 'Date updated' },
  phone: { id: 'app.accounts.phone', defaultMessage: 'Phone' },
  addNewContact: { id: 'app.accounts.addNewContact', defaultMessage: 'Add new contact' },
  editContact: { id: 'app.account.editContact', defaultMessage: 'Edit contact' },
  editSaleCase: { id: 'app.accounts.editSaleCase', defaultMessage: 'Edit sale case' },
  salesRepresentative: { id: 'app.accounts.salesRepresentative', defaultMessage: 'Sales representative' },
  saveAcc: { id: 'app.accounts.saveAcc', defaultMessage: 'Save account' },
  createAcc: { id: 'app.accounts.createAcc', defaultMessage: 'Create account' },
  newAcc: { id: 'app.accounts.newAcc', defaultMessage: 'New account' },
  companyRegistrationNumber: { id: 'app.accounts.companyRegistrationNumber', defaultMessage: 'Company registration number' },
  customerName: { id: 'app.accounts.customerName', defaultMessage: 'Customer name' },
  offer: { id: 'app.accounts.offer', defaultMessage: 'Offer' },
  addSaleCase: { id: 'app.accounts.addSaleCase', defaultMessage: 'Add sale case' },
  saleCases: { id: 'app.accounts.saleCases', defaultMessage: 'Sale cases' },
  business: { id: 'app.accounts.business', defaultMessage: 'Business' },
  private: { id: 'app.accounts.private', defaultMessage: 'Private' },
  statusOpen: { id: 'app.accounts.statusOpen', defaultMessage: 'Open' },
  statusClosed: { id: 'app.accounts.statusClosed', defaultMessage: 'Closed' },
  searchPlaceholder: { id: 'app.accounts.searchPlaceholder', defaultMessage: 'Search accounts...' },
  createNewAccount: { id: 'app.accounts.createNewAccount', defaultMessage: 'Create new account' },
  filterAccounts: { id: 'app.accounts.filterAccounts', defaultMessage: 'Filter accounts...' },
  accountName: { id: 'app.accounts.accountName', defaultMessage: 'Account name' },
  salesCase: { id: 'app.accounts.salesCase', defaultMessage: 'Sales case' },
  vat: { id: 'app.accounts.number', defaultMessage: 'VAT number' },
  accountNo: { id: 'app.accounts.accountNo', defaultMessage: 'Account number' },
  searchAccounts: { id: 'app.accounts.searchAccounts', defaultMessage: 'Search accounts...' },
  refreshAccount: { id: 'Refresh account', defaultMessage: 'Refresh account' },
  externalId: { id: 'External account ID', defaultMessage: 'External account ID' },
  favAccounts: { id: 'Favourite accounts', defaultMessage: 'Favourite accounts' },
  crmId: { id: 'CRM ID', defaultMessage: 'CRM ID' },
  successMsgAccountActivate: { id: 'app.activate.successMsg', defaultMessage: 'Your account was successfully activated!' },
  failMsgMsgAccountActivate: { id: 'app.activate.failMsg', defaultMessage: 'Activation failed.' },
  progressMsgAccountAvtivate: { id: 'app.activate.progressMsg', defaultMessage: 'Activation in progress...' },
  settings: { id: 'app.navigationBar.settingsIconLabel', defaultMessage: 'Settings' },
  logOut: { id: 'app.navigationBar.logoutIconLabel', defaultMessage: 'Log out' },
  cpt: { id: 'app.navigationBar.commercialProductCatalog', defaultMessage: 'Commercial Product Catalog' },
  myTasks: { id: 'app.navigationBar.myTasks', defaultMessage: 'My Tasks' },
  accounts: { id: 'app.navigationBar.accounts', defaultMessage: 'Accounts' },
  myDeliveries: { id: 'app.navigationBar.myDeliveries', defaultMessage: 'My deliveries' },
  deliveries: { id: 'Deliveries', defaultMessage: 'Deliveries' },
  orderManagement: { id: 'Order management', defaultMessage: 'Order management' },
  tasks: { id: 'app.navigationBar.tasks', defaultMessage: 'Tasks' },
  pipeline: { id: 'Pipeline', defaultMessage: 'Pipeline' },
  myOrders: { id: 'My Orders', defaultMessage: 'My Orders' },
  bulletin: { id: 'Bulletin board', defaultMessage: 'Bulletin board' },
  home: { id: 'Home', defaultMessage: 'Home' },
  loginFail: { id: 'app.auth.loginFail', defaultMessage: 'Login failed' },
  username: { id: 'app.auth.username', defaultMessage: 'Username' },
  password: { id: 'app.auth.password', defaultMessage: 'Password' },
  signIn: { id: 'app.auth.signin', defaultMessage: 'Sign in' },
  markAsVisited: { id: 'Mark as viewed', defaultMessage: 'Mark as viewed' },
  bulletinBoard: { id: 'Bulletin board messages', defaultMessage: 'Bulletin board messages' },
  addProductOffering: { id: 'app.comercialCatalog.addNewProduct', defaultMessage: 'Add new product offering' },
  searchProductPlaceholder: { id: 'app.comercialCatalog.toolbar.searchPlaceholder', defaultMessage: 'Search product offerings...' },
  active: { id: 'app.products.const.active', defaultMessage: 'Active' },
  inactive: { id: 'app.products.const.inactive', defaultMessage: 'Inactive' },
  agree: { id: 'app.common.confirmation.agree', defaultMessage: 'Agree' },
  disagree: { id: 'app.common.confirmation.disagree', defaultMessage: 'Disagree' },
  newPassword: { id: 'app.offer.change.password.form.new.password', defaultMessage: 'New password' },
  confirmPassword: { id: 'app.offer.change.password.form.confirm.password', defaultMessage: 'Confirm password' },
  minPasswordLength: { id: 'app.offer.change.password.form.min.length.error', defaultMessage: 'Password must be at least {minLength} characters long' },
  passwordsNotMatch: { id: 'app.offer.change.password.form.passwords.match.error', defaultMessage: `Passwords don't match` },
  submit: { id: 'app.offer.change.password.form.submit.button', defaultMessage: 'Submit' },
  editWithJsonEditor: { id: 'common.json.field.label', defaultMessage: 'Edit with JSON editor' },
  richTextPlaceholderText: { id: 'app.common.forms.placeholderText', defaultMessage: 'Type text here...' },
  more: { id: 'app.common.forms.richText.styleMenu', defaultMessage: 'More' },
  required: { id: 'app.common.forms.validationRules.required', defaultMessage: 'Required' },
  mustBeNumber: { id: 'app.common.forms.validationRules.number', defaultMessage: 'Must be a number' },
  DEFAULT: { id: 'made a change', defaultMessage: 'made a change' },
  none: { id: 'None', defaultMessage: 'None' },
  field: { id: 'Field', defaultMessage: 'Field' },
  originalValue: { id: 'Original Value', defaultMessage: 'Original Value' },
  newValue: { id: 'New Value', defaultMessage: 'New Value' },
  eventType: { id: 'Event type', defaultMessage: 'Event type' },
  noResultsTable: { id: 'app.common.noResults', defaultMessage: 'There are no results.' },
  primaryContact: { id: 'Primary contact', defaultMessage: 'Primary contact' },
  noContacts: { id: 'No contacts defined in Super Office', defaultMessage: 'No contacts defined in Super Office' },
  user: { id: 'app.common.user', defaultMessage: 'User' },
  versionChanges: { id: 'The {entityName} you are currently viewing has been changed. Please refresh page to view latest changes.', defaultMessage: 'The {entityName} you are currently viewing has been changed. Please refresh page to view latest changes.' },
  taskChanged: { id: 'The task you are currently viewing has been changed.', defaultMessage: 'The task you are currently viewing has been changed.' },
  refresh: { id: 'Refresh', defaultMessage: 'Refresh' },
  expDelivery: { id: 'Exp. delivery', defaultMessage: 'Exp. delivery' },
  expDeliveryStart: { id: 'Exp. delivery start', defaultMessage: 'Exp. delivery start' },
  claimingTask: { id: 'app.myTasks.actions.claiming', defaultMessage: 'Claiming the task...' },
  claimedTask: { id: 'app.myTasks.actions.claimed', defaultMessage: 'Task claimed.' },
  failClaimTask: { id: 'app.myTasks.actions.fail', defaultMessage: 'Claiming task failed.' },
  claim: { id: 'app.myTasks.components.claim', defaultMessage: 'Claim' },
  assignee: { id: 'app.myTasks.components.table.assignee', defaultMessage: 'Assignee' },
  created: { id: 'app.myTasks.components.table.created', defaultMessage: 'Created' },
  noRoute: { id: 'app.noRoute', defaultMessage: 'URL you are trying to reach is under development. Sorry!' },
  downloadPdf: { id: 'app.offers.common.createPdfBtn', defaultMessage: 'Download PDF' },
  duplicateOffer: { id: 'app.offers.common.duplicateOffer', defaultMessage: 'Duplicate an offer' },
  draft: { id: 'app.offers.const.draft', defaultMessage: 'Draft' },
  awaiting: { id: 'app.offers.const.awaiting', defaultMessage: 'Awaiting for approval' },
  approved: { id: 'app.offers.const.approved', defaultMessage: 'Approved' },
  rejected: { id: 'app.offers.const.rejected', defaultMessage: 'Rejected' },
  archived: { id: 'app.offers.const.archived', defaultMessage: 'Archived' },
  deleted: { id: 'app.offers.const.deleted', defaultMessage: 'Deleted' },
  successOfferAdd: { id: 'app.offers.actions.add.success', defaultMessage: 'Offer successfully added!' },
  failOfferAdd: { id: 'app.offers.actions.add.fail', defaultMessage: 'Sorry, adding an offer failed' },
  successDeleteOffer: { id: 'app.offers.actions.delete.success', defaultMessage: 'Offer successfully deleted!' },
  failDeleteOffer: { id: 'app.offers.actions.delete.fail', defaultMessage: 'Sorry, deleting an offer failed' },
  successEditOffer: { id: 'app.offer.actions.edit.succcess', defaultMessage: 'Offer successfully saved!' },
  failEditOffer: { id: 'app.offer.actions.edit.fail', defaultMessage: 'Sorry, saving an offer failed' },
  successRestoreOffer: { id: 'app.offers.actions.restore.success', defaultMessage: 'Offer successfully sent to draft!' },
  failRestoreOffer: { id: 'app.offers.actions.restore.fail', defaultMessage: 'Sorry, sending the offer back to draft failed' },
  chooseFollowingProducts: { id: 'app.offer.details.common.chooseProductDialog.title', defaultMessage: 'Please choose one of the following products' },
  addAllProducts: { id: 'app.offer.details.common.chooseProductDialog.addAll', defaultMessage: 'Add selected products' },
  noProducts: { id: 'app.offer.details.common.chooseProductDialog.noResults', defaultMessage: 'There are no products.' },
  rejectOffer: { id: 'app.offer.details.common.reject.title', defaultMessage: 'Reject the offer' },
  rejectReason: { id: 'app.offer.details.common.reject.content', defaultMessage: 'Please provide the reason why you are going to reject the offer:' },
  reason: { id: 'app.offer.details.common.reject.reason', defaultMessage: 'Reason' },
  reject: { id: 'app.offer.details.common.reject.reject', defaultMessage: 'Reject' },
  duplicatingOffer: { id: 'app.offer.details.header.actions.duplicate.duplicating', defaultMessage: 'Duplicating offer...' },
  successDuplicateOffer: { id: 'app.offer.details.header.actions.duplicate.success', defaultMessage: 'Offer succesfully duplicated!' },
  redirect: { id: 'app.offer.details.header.actions.duplicate.redirect', defaultMessage: 'Redirect' },
  sendingOffer: { id: 'app.offer.details.header.actions.send.sending', defaultMessage: 'Sending the offer...' },
  sentOffer: { id: 'app.offer.details.header.actions.send.sent', defaultMessage: 'Offer sent to approval.' },
  approveOffer: { id: 'app.offer.details.header.components.approveBtn.approve', defaultMessage: 'Approve the Offer' },
  createOrder: { id: 'Create order', defaultMessage: 'Create order' },
  disabledCreateOrder: { id: 'Enter account ID to be able to create an order', defaultMessage: 'Enter account ID to be able to create an order' },
  warningDeleteOffer: { id: 'app.offer.details.header.components.delete.confirmation', defaultMessage: 'Offer will be removed permanently.' },
  deleteOffer: { id: 'app.offer.details.header.components.delete.title', defaultMessage: 'Delete the offer' },
  offerStatus: { id: 'app.offer.details.header.components.status.status', defaultMessage: 'Offer status' },
  restoreOfferToDraft: { id: 'app.offer.details.header.components.restore.title', defaultMessage: 'Restore offer to draft' },
  sendBackToDraft: { id: 'app.offer.details.header.components.sendBackToDraft', defaultMessage: 'Send back to draft' },
  sendToApprove: { id: 'app.offer.details.header.components.sendBtn.send', defaultMessage: 'Send to approve' },
  priceTotals: { id: 'app.offer.totals.price', defaultMessage: 'PRICE' },
  costTotals: { id: 'app.offer.totals.cost', defaultMessage: 'COST' },
  profitTotals: { id: 'app.offer.totals.profit', defaultMessage: 'PROFIT' },
  commissionTotals: { id: 'app.offer.totals.comission', defaultMessage: 'COMMISION' },
  editProduct: { id: 'app.offer.details.form.addProduct.editProduct', defaultMessage: 'Edit product' },
  showDescInPdf: { id: 'app.offer.details.form.addProduct.showDescInPdf', defaultMessage: 'Show description in offer pdf' },
  showProdInPdf: { id: 'app.offer.details.form.addProduct.showProdInPdf', defaultMessage: 'Show product in offer pdf' },
  deliveryHouseNumber: { id: 'app.offer.details.form.addProduct.deliveryHouseNumber', defaultMessage: 'Delivery house number' },
  deliveryFloor: { id: 'app.offer.details.form.addProduct.deliveryFloor', defaultMessage: 'Delivery floor' },
  deliveryDoor: { id: 'app.offer.details.form.addProduct.deliveryDoor', defaultMessage: 'Delivery door' },
  tdcOrderNumber: { id: 'app.offer.details.form.addProduct.tdcOrderNumber', defaultMessage: 'TDC order number' },
  prices: { id: 'app.offer.details.form.addProduct.prices.title', defaultMessage: 'Prices' },
  offerProducts: { id: 'app.offer.details.summary.form.offerProducts', defaultMessage: 'Offer products' },
  productSectionErrorName: { id: 'app.offer.details.form.validation.products.section.same.name', defaultMessage: 'This name is already taken' },
  placeholderSearchAccounts: { id: 'app.offer.details.form.customer.autocomplete.placeholder', defaultMessage: 'Search accounts...' },
  successAddSaleCase: { id: 'app.offers.customer.actions.addSaleCase.success', defaultMessage: 'Sale case successfully added!' },
  failAddSaleCase: { id: 'app.offers.customer.actions.addSaleCase.fail', defaultMessage: 'Sorry, adding an sale case failed' },
  companyName: { id: 'app.offer.details.form.customer.companyName', defaultMessage: 'Company name' },
  approvalGroup: { id: 'app.offer.details.form.history.approvalGroup', defaultMessage: 'Approval group' },
  createdDate: { id: 'app.offer.details.form.history.createdDate', defaultMessage: 'Created date' },
  resDate: { id: 'app.offer.details.form.history.resDate', defaultMessage: 'Resolution date' },
  productGroup: { id: 'app.offer.details.form.products.atucomplete.productgroup', defaultMessage: 'Product group' },
  placeholderSearchProduct: { id: 'app.offer.details.form.products.atucomplete.placeholder', defaultMessage: 'Type to search product...' },
  placeholderFirstLine: { id: 'app.offer.details.offerForm.section.products.list.placeholder.first.line', defaultMessage: 'Add or drop product here.' },
  placeholderSecondLine: { id: 'app.offer.details.offerForm.section.products.list.placeholder.second.line', defaultMessage: 'Section without products will be removed after page refresh.' },
  warningRemoveProduct: { id: 'app.offer.details.offerForm.producs.list.row.warning', defaultMessage: 'Product offering will be removed permanently.' },
  warningRemoveSection: { id: 'app.offer.details.offerForm.section.delete.warning', defaultMessage: 'Section will be removed permanently.' },
  duplicateSection: { id: 'app.offer.details.offerForm.section.duplicate', defaultMessage: 'Duplicate section' },
  toggleValidationResults: { id: 'app.offer.details.form.products.list.errors.toggleResults', defaultMessage: 'Toggle validation results' },
  addNewSection: { id: 'app.offer.productSections.addNewSection', defaultMessage: 'Add new section' },
  addFiber: { id: 'app.offer.productSecitons.addFiber', defaultMessage: 'Add fiber' },
  expiry: { id: 'app.offer.details.overview.expiry', defaultMessage: 'Expiration' },
  contractLengthOptions: { id: 'Contract length (1 - 60 months)', defaultMessage: 'Contract length (1 - 60 months)' },
  offerTemplate: { id: 'app.offer.details.overview.template', defaultMessage: 'Offer template' },
  add: { id: 'app.offer.details.overview.actions.add', defaultMessage: 'Add' },
  goToAccountAutocomplete: { id: 'app.offer.details.overview.actions.go.to.account.autocomplete', defaultMessage: 'Set account' },
  goToSaleCaseAutocomplete: { id: 'app.offer.details.overview.actions.go.to.sale.case.autocomplete', defaultMessage: 'Set sale case' },
  notifications: { id: 'app.offer.details.overview.validation.notifications', defaultMessage: 'Notifications' },
  addTitlePlaceholder: { id: 'app.offer.details.form.summary.addTitlePlaceholder', defaultMessage: 'Add description title...' },
  addNewDescription: { id: 'app.offer.details.form.summary.addNewDescription', defaultMessage: 'Add new offer description' },
  warningRemoveDescription: { id: 'app.offer.details.form.summary.warning', defaultMessage: 'Offer description will be removed permanently.' },
  restoreDescriptions: { id: 'Restore removed descriptions from template', defaultMessage: 'Restore removed descriptions from template' },
  ID: { id: 'app.offer.list.table.id', defaultMessage: 'ID' },
  ageOfOffer: { id: 'app.offer.list.table.ageOfOffer', defaultMessage: 'Age of the offer in days.' },
  addNewDraft: { id: 'app.offers.addNewDraft', defaultMessage: 'Add new draft' },
  successChangePassword: { id: 'app.offer.change.password.success', defaultMessage: 'Password changed! Plase log in with new password.' },
  failChangePassword: { id: 'app.offer.change.password.fail', defaultMessage: 'Sorry, changing password failed' },
  saveChanges: { id: 'app.personalSettings.save', defaultMessage: 'Save changes' },
  english: { id: 'app.personalSettings.english', defaultMessage: 'English' },
  danish: { id: 'app.personalSettings.danish', defaultMessage: 'Danish' },
  displayCosts: { id: 'app.personalSettings.costs', defaultMessage: 'Display costs.' },
  displayCommission: { id: 'app.personalSettings.commission', defaultMessage: 'Display commission.' },
  displayProfits: { id: 'app.personalSettings.profits', defaultMessage: 'Display profits.' },
  displayOfferElement: { id: 'app.personalSettings.offerElement', defaultMessage: 'Display offer element side bar.' },
  selectLanguage: { id: 'app.personalSettings.language', defaultMessage: 'Select application language.' },
  spellcheck: { id: 'Spellcheck', defaultMessage: 'Spellcheck' },
  personalSettings: { id: 'app.personalSettings.title', defaultMessage: 'Personal settings' },
  basic: { id: 'app.personalSettings.tab.basic.label', defaultMessage: 'Basic' },
  changePassword: { id: 'app.personalSettings.tab.change.password.label', defaultMessage: 'Change password.' },
  successMsgAddProduct: { id: 'app.productOfferingDetails.addProduct.successMsg', defaultMessage: 'Product offering successfully added!' },
  failMsgAddProduct: { id: 'app.productOfferingDetails.addProduct.failMsg', defaultMessage: 'Sorry, adding product offering failed' },
  successEditProduct: { id: 'app.offeringDetails.actions.edit.saved', defaultMessage: 'Product offering successfully saved!' },
  failEditProduct: { id: 'app.offeringDetails.actions.edit.fail', defaultMessage: 'Sorry, saving product offering failed' },
  saveProductOffering: { id: 'app.offeringDetails.components.header.saved', defaultMessage: 'Save product offering' },
  createProductOffering: { id: 'app.offeringDetails.components.header.create', defaultMessage: 'Create product offering' },
  orderIndex: { id: 'app.offeringDetails.components.form.orderIndex', defaultMessage: 'Order index' },
  unitOfMeasure: { id: 'app.offeringDetails.components.form.unit', defaultMessage: 'Unit of measure' },
  offerValidators: { id: 'app.offeringDetails.components.form.offerValidators', defaultMessage: 'Offer validators' },
  offerApprovalSelectors: { id: 'app.offeringDetails.components.form.offerApprovalSelectors', defaultMessage: 'Offer approval selectors' },
  specification: { id: 'app.offeringDetails.components.form.specification', defaultMessage: 'Specification' },
  addSpecification: { id: 'app.offeringDetails.components.form.addSpecification', defaultMessage: 'Add specification' },
  additionalMetadata: { id: 'app.offeringDetails.components.form.additionalMetadata', defaultMessage: 'Additional metadata' },
  addAdditionalMetadata: { id: 'app.offeringDetails.components.form.addAdditionalMetadata', defaultMessage: 'Add additional metadata' },
  404: { id: 'The requested URL was not found.', defaultMessage: 'The requested URL was not found.' },
  403: { id: "You don't have permission to access this page", defaultMessage: "You don't have permission to access this page." },
  successResetPassword: { id: 'app.offer.reset.password.success', defaultMessage: 'Password reseted! Plase log in with new password.' },
  failResetPassword: { id: 'app.offer.reset.password.fail', defaultMessage: 'Sorry, reseting password failed' },
  successResetInit: { id: 'app.offer.init.reset.password.success', defaultMessage: 'Check your emails for details on how to reset your password.' },
  enterEmail: { id: 'app.reset.password.init.enter.email', defaultMessage: 'Enter the email address you used to register' },
  resetPassword: { id: 'app.reset.password.init.submit.button', defaultMessage: 'Reset password' },
  enterPassword: { id: 'app.reset.password.reset.enter.password', defaultMessage: 'Please provide your new password' },
  missingKey: { id: 'app.reset.password.reset.missing.key', defaultMessage: 'Missing key paramter in the url. Plase try reset password first.' },
  completingTask: { id: 'app.taskDetails.actions.completing', defaultMessage: 'Completing the task...' },
  completedTask: { id: 'app.taskDetails.actions.completed', defaultMessage: 'Task completed.' },
  subject: { id: 'Subject', defaultMessage: 'Subject' },
  LOW: { id: 'Low', defaultMessage: 'Low' },
  MEDIUM: { id: 'Medium', defaultMessage: 'Medium' },
  HIGH: { id: 'High', defaultMessage: 'High' },
  priority: { id: 'Priority', defaultMessage: 'Priority' },
  createNew: { id: 'Create new', defaultMessage: 'Create new' },
  taskFieldPlaceholder: { id: 'Type task name', defaultMessage: 'Type task name' },
  taskField: { id: 'Task', defaultMessage: 'Task' },
  clearFilters: { id: 'Clear filters', defaultMessage: 'Clear filters' },
  defaultErr: { id: 'app.utils.http.defaultErr', defaultMessage: 'An error occured' },
  noResponse: { id: 'app.utils.http.noResponse', defaultMessage: 'No response was received' },
  admin: { id: 'app.utils.userLabel.admin', defaultMessage: 'Admin' },
  groupAdmins: { id: 'Group Admins', defaultMessage: 'Group Admins' },
  groupManagers: { id: 'Group Managers', defaultMessage: 'Group Managers' },
  groupTechnical: { id: 'Group Technical', defaultMessage: 'Group Technical' },
  groupIT: { id: 'Group IT', defaultMessage: 'Group IT' },
  salesCaseManager: { id: 'Sales case Manager', defaultMessage: 'Sales case Manager' },
  activitiUser: { id: 'app.utils.userLabel.activitiUser', defaultMessage: 'Activiti user' },
  experimental: { id: 'Experimental', defaultMessage: 'Experimental' },
  searchOffers: { id: 'app.offers.list.toolbar.search', defaultMessage: 'Search offers...' },
  outcome: { id: 'Outcome', defaultMessage: 'Outcome' },
  startTask: { id: 'Start task', defaultMessage: 'Start task' },
  modeler: { id: 'Modeler', defaultMessage: 'Modeler' },
  version: { id: 'Version', defaultMessage: 'Version' },
  deployProcess: { id: 'Deploy process', defaultMessage: 'Deploy process' },
  deployProcessSuccess: { id: 'Process sucessfuly deployed!', defaultMessage: 'Process sucessfuly deployed!' },
  deployProcessFail: { id: 'Deployment of process failed!', defaultMessage: 'Deployment of process failed!' },
  addProcess: { id: 'Add new process', defaultMessage: 'Add new process' },
  addProcessSuccess: { id: 'Process sucessfuly added!', defaultMessage: 'Process sucessfuly added!' },
  addProcessFail: { id: 'Adding process failed!', defaultMessage: 'Adding process failed!' },
  value: { id: 'modeler.variable.value', defaultMessage: 'Value' },
  expression: { id: 'modeler.variable.expression', defaultMessage: 'Expression' },
  variables: { id: 'modeler.variables.header', defaultMessage: 'Task definition variables' },
  label: { id: 'modeler.fields.label', defaultMessage: 'Label' },
  manualTaskFields: { id: 'modeler.manua.task.form.fields.title', defaultMessage: 'Additional form fields' },
  startProcess: { id: 'Start process', defaultMessage: 'Start process' },
  explanation: { id: 'Explanation', defaultMessage: 'Explanation' },
  processVariables: { id: 'Process variables', defaultMessage: 'Process variables' },
  addVariable: { id: 'Add variable', defaultMessage: 'Add variable' },
  startProcessSuccess: { id: 'Process sucessfuly started!', defaultMessage: 'Process sucessfuly started!' },
  startProcessFail: { id: 'Starting process failed!', defaultMessage: 'Starting process failed!' },
  saveProcessSuccess: { id: 'Process sucessfuly saved!', defaultMessage: 'Process sucessfuly saved!' },
  saveProcessFail: { id: 'Saving process failed!', defaultMessage: 'Saving process failed!' },
  dictionaries: { id: 'Dictionaries', defaultMessage: 'Dictionaries' },
  code: { id: 'Code', defaultMessage: 'Code' },
  id: { id: 'ID', defaultMessage: 'ID' },
  serviceUnavailable: { id: `We're sorry, service you are trying to view is currently unavailable. Please try again later.`, defaultMessage: `We're sorry, service you are trying to view is currently unavailable. Please try again later.` },
  warningRemoveSalesCase: { id: 'Sales case will be removed permanently', defaultMessage: 'Sales case will be removed permanently' },
  addDictionary: { id: 'Add dictionary', defaultMessage: 'Add dictionary' },
  requiredFieldsError: { id: 'Fill all required fields', defaultMessage: 'Fill all required fields' },
  addEntry: { id: 'Add entry', defaultMessage: 'Add entry' },
  dictionaryId: { id: 'Dictionary ID', defaultMessage: 'Dictionary ID' },
  process: { id: 'Process', defaultMessage: 'Process' },
  initiator: { id: 'Initiator', defaultMessage: 'Initiator' },
  startDate: { id: 'Start date', defaultMessage: 'Start date' },
  endDate: { id: 'End date', defaultMessage: 'End date' },
  activeProcesses: { id: 'Active processes', defaultMessage: 'Active processes' },
  processDefinition: { id: 'Process definition', defaultMessage: 'Process definition' },
  processes: { id: 'Processes', defaultMessage: 'Processes' },
  RUNNING: { id: 'Running', defaultMessage: 'Running' },
  activityName: { id: 'Activity name', defaultMessage: 'Activity name' },
  activityType: { id: 'Activity type', defaultMessage: 'Activity type' },
  processDetails: { id: 'Process details', defaultMessage: 'Process details' },
  processDefinitionId: { id: 'Process Definition ID', defaultMessage: 'Process Definition ID' },
  processInstanceId: { id: 'Process Instance ID', defaultMessage: 'Process Instance ID' },
  tenantId: { id: 'Tenant ID', defaultMessage: 'Tenant ID' },
  tableConfigDialogTitle: { id: 'Set table columns visibility', defaultMessage: 'Set table columns visibility' },
  tableConfiguration: { id: 'Table Configuration', defaultMessage: 'Table Configuration' },
  selectResources: { id: 'Select resources', defaultMessage: 'Select resources' },
  activities: { id: 'Activities', defaultMessage: 'Activities' },
  addAllResources: { id: 'Add selected', defaultMessage: 'Add selected' },
  noResultsTryTo: { id: 'No results. Try to:', defaultMessage: 'No results. Try to:' },
  resourcesSIM_CARD: { id: 'SIM Card', defaultMessage: 'SIM Card' },
  resourcesPHONE_NUMBER: { id: 'Phone number', defaultMessage: 'Phone number' },
  resourcesPHONE_NUMBERS_RANGE: { id: 'Phone numbers range', defaultMessage: 'Phone numbers range' },
  icc: { id: 'ICC', defaultMessage: 'ICC' },
  imsi: { id: 'IMSI', defaultMessage: 'IMSI' },
  fixedPhoneNumber: { id: 'Fixed', defaultMessage: 'Fixed' },
  mobilePhoneNumber: { id: 'Mobile', defaultMessage: 'Mobile' },
  resourcesUnknownType: { id: 'Unknown resource type', defaultMessage: 'Unknown resource type' },
  errors: { id: 'Errors', defaultMessage: 'Errors' },
  processHistory: { id: 'Process history', defaultMessage: 'Process history' },
  processDefinitionName: { id: 'Prodess definition name', defaultMessage: 'Process definition name' },
  processName: { id: 'Process name', defaultMessage: 'Process name' },
  time: { id: 'Time', defaultMessage: 'Time' },
  errorDetails: { id: 'Error details', defaultMessage: 'Error details' },
  showOnDiagram: { id: 'Show on diagram', defaultMessage: 'Show on diagram' },
  stackTrace: { id: 'Stack trace', defaultMessage: 'Stack trace' },
  diagram: { id: 'Diagram', defaultMessage: 'Diagram' },
  myTasksCategoriesPlaceholder: { id: 'No categories defined. Please, contact an admin.', defaultMessage: 'No categories defined. Please, contact an admin.' },
  markOrderToExport: { id: 'Mark order to export', defaultMessage: 'Mark order to export' },
  exportedInitial: { id: 'Exported initial', defaultMessage: 'Exported initial' },
  exportedRecurring: { id: 'Exported recurring', defaultMessage: 'Exported recurring' },
  orderMarkedToExportSuccess: { id: 'Order marked to export successfully!', defaultMessage: 'Order marked to export successfully!' },
  orderMarkedToExportFail: { id: 'Marking the order to export failed', defaultMessage: 'Marking the order to export failed' },
  markOrderToExportWarning: { id: 'Order will be marked to export. Operation can be done only once and cannot be reverted.', defaultMessage: 'Order will be marked to export. Operation can be done only once and cannot be reverted.' },
  customerCare: { id: 'Customer care', defaultMessage: 'Customer care' },
  activityId: { id: 'Activity ID', defaultMessage: 'Activity ID' },
  bpmn: { id: 'BPMN', defaultMessage: 'BPMN' },
  noVariablesDefined: { id: 'No process variables defined', defaultMessage: 'No process variables defined' },
  attachementLinks: { id: 'Attachement links', defaultMessage: 'Attachement links' },
  addSubscription: { id: 'Add subscription', defaultMessage: 'Add subscription' },
  createSubscription: { id: 'Create subscription', defaultMessage: 'Create subscription' },
  product: { id: 'Product', defaultMessage: 'Product' },
  validate: { id: 'Validate', defaultMessage: 'Validate' },
  selectGroupingOption: { id: 'Select grouping option', defaultMessage: 'Select grouping option' },
  groupByCatgory: { id: 'group by product category', defaultMessage: 'group by product category' },
  preserveGrouping: { id: 'preserve grouping of offer lines', defaultMessage: 'preserve grouping of offer lines' },
  dontGroup: { id: `don't group`, defaultMessage: `don't group` },
  reservationResourceFaild: { id: 'Reservation of resource failed.', defaultMessage: 'Reservation of resource failed.' },
  noResourcesMsg: { id: 'No resources selected.', defaultMessage: 'No resources selected.' },
  noResourceMsg: { id: 'No resource selected.', defaultMessage: 'No resource selected.' },
  deleteProcessInstanceTitle: { id: 'Delete process: {id}.', defaultMessage: 'Delete process: {id}.' },
  deleteProcessInstanceDescription: { id: 'Process with id: "{id}" will be removed permanently. Are you sure?', defaultMessage: 'Process with id: "{id}" will be removed permanently. Are you sure?' },
  successDeleteProcess: { id: 'Process successfully deleted!', defaultMessage: 'Process successfully deleted!' },
  failDeleteProcess: { id: 'Sorry, deleting process failed.', defaultMessage: 'Sorry, deleting process failed.' },
  idAlreadyDefined: { id: 'ID {id} is already defined', defaultMessage: 'ID {id} is already defined' },
  selectOrAddDelivery: { id: 'Select or add new delivery', defaultMessage: 'Select or add new delivery' },
  groupProductsBy: { id: 'Group offer products by:', defaultMessage: 'Group offer products by:' },
  productSections: { id: 'Product sections', defaultMessage: 'Product sections' },
  other: { id: 'Other', defaultMessage: 'Other' },
  features: { id: 'Features', defaultMessage: 'Features' },
  goToProcessDetails: { id: 'See the process details.', defaultMessage: 'See the process details.' },
  postponeCompletingForm: { id: 'Postpone completing the form', defaultMessage: 'Postpone completing the form' },
  reloadEntity: { id: 'Reload the entity', defaultMessage: 'Reload the entity' },
  exceptions: { id: 'Exceptions', defaultMessage: 'Exceptions' },
  fields: { id: 'Fields', defaultMessage: 'Fields' },
  refreshTimes: { id: 'Refresh times(in seconds)', defaultMessage: 'Refresh times(in seconds)' },
  refreshNow: { id: 'Refresh now', defaultMessage: 'Refresh now' },
  disable: { id: 'Disable', defaultMessage: 'Disable' },
  productTask: { id: 'Product task', defaultMessage: 'Product task' },
  uploadFile: { id: 'Drag & Drop your files or Browse', defaultMessage: 'Drag & Drop your files or Browse' },
  template: { id: 'Template', defaultMessage: 'Template' },
  addTemplate: { id: 'Add template', defaultMessage: 'Add template' },
  editTemplate: { id: 'Edit template', defaultMessage: 'Edit template' },
  templates: { id: 'Templates', defaultMessage: 'Templates' },
  rootAccount: { id: 'Root account', defaultMessage: 'Root account' },
  line: { id: 'Line', defaultMessage: 'Line' },
  result: { id: 'Result', defaultMessage: 'Result' },
  severity: { id: 'Severity', defaultMessage: 'Severity' },
  problem: { id: 'Problem', defaultMessage: 'Problem' },
  featureKey: { id: 'Feature key', defaultMessage: 'Feature key' },
  attributeKey: { id: 'Attribute key', defaultMessage: 'Attribute key' },
  validationResults: { id: 'Validation results', defaultMessage: 'Validation results' },
  importResults: { id: 'Import results', defaultMessage: 'Import results' },
  showValidationResults: { id: 'Show validation results', defaultMessage: 'Show validation results' },
  showImportResults: { id: 'Show import results', defaultMessage: 'Show import results' },
  openInNewTab: { id: 'Open in new tab', defaultMessage: 'Open in new tab' },
  duplicatedHeaders: { id: 'Duplicated headers', defaultMessage: 'Duplicated headers' },
  missingHeaders: { id: 'Missing headers', defaultMessage: 'Missing headers' },
  unrecognizedHeaders: { id: 'Unrecognized headers', defaultMessage: 'Unrecognized headers' },
  validationProgressInfo: { id: '{currentlyProcessedLine} out of {totalNumberOfLines} have been processed', defaultMessage: '{currentlyProcessedLine} out of {totalNumberOfLines} have been processed' },
  new: { id: 'New', defaultMessage: 'New' },
  validationInProgress: { id: 'Validation in progress', defaultMessage: 'Validation in progress' },
  validationCompleted: { id: 'Validation completed', defaultMessage: 'Validation completed' },
  validationFailed: { id: 'Validation failed', defaultMessage: 'Validation failed' },
  dataInvalid: { id: 'Data is invalid', defaultMessage: 'Data is invalid' },
  valid: { id: 'Valid', defaultMessage: 'Valid' },
  invalid: { id: 'Invalid', defaultMessage: 'Invalid' },
  importCompleted: { id: 'Import completed', defaultMessage: 'Import completed' },
  importInProgress: { id: 'Import in progress', defaultMessage: 'Import in progress' },
  importFailed: { id: 'Import failed', defaultMessage: 'Import failed' },
  generateCsv: { id: 'Generate CSV', defaultMessage: 'Generate CSV' },
  orderLineState: { id: 'Order line state', defaultMessage: 'Order line state' },
  setReason: { id: 'Set reason', defaultMessage: 'Set reason' },
  presaler: { id: 'Presaler', defaultMessage: 'Presaler' },
  addMultipleSubscriptions: { id: 'Add multiple subscriptions', defaultMessage: 'Add multiple subscriptions' },
  addUser: { id: 'Add user', defaultMessage: 'Add user' },
  users: { id: 'Users', defaultMessage: 'Users' },
  login: { id: 'Login', defaultMessage: 'Login' },
  activated: { id: 'Activated', defaultMessage: 'Activated' },
  roles: { id: 'Roles', defaultMessage: 'Roles' },
  lastModifiedBy: { id: 'Last modified by', defaultMessage: 'Last modified by' },
  lastModifiedDate: { id: 'Last modified date', defaultMessage: 'Last modified date' },
  editUser: { id: 'Edit user', defaultMessage: 'Edit user' },
  productRole: { id: 'Product role', defaultMessage: 'Product role' },
  import: { id: 'Import', defaultMessage: 'Import' },
  catalogs: { id: 'Catalogs', defaultMessage: 'Catalogs' },
  addCatalog: { id: 'Add catalog', defaultMessage: 'Add catalog' },
  successCatalogAdd: { id: 'Catalog successfully created!', defaultMessage: 'Catalog successfully created!' },
  failCatalogAdd: { id: 'Sorry, creating an catalog failed', defaultMessage: 'Sorry, creating an catalog failed' },
  saveCatalog: { id: 'Save catalog', defaultMessage: 'Save catalog' },
  addAccount: { id: 'Add account', defaultMessage: 'Add account' },
  newCatalog: { id: 'New catalog', defaultMessage: 'New catalog' },
  searchCatalogs: { id: 'Search catalogs', defaultMessage: 'Search catalogs' },
  filterByAccount: { id: 'Filter by account', defaultMessage: 'Filter by account' },
  count: { id: 'Count', defaultMessage: 'Count' },
  createdSubscriptionsCount: { id: 'Number of created subscriptions', defaultMessage: 'Number of created subscriptions' },
  permissionRejectSubscriptionValue: { id: `You don't have permission to access this content`, defaultMessage: `You don't have permission to access this content` },
  successEditCatalog: { id: 'Catalog succesfully saved!', defaultMessage: 'Catalog succesfully saved!' },
  failEditCatalog: { id: 'Sorry, saving catalog failed', defaultMessage: 'Sorry, saving catalog failed' },
  swagger: { id: 'Swagger', defaultMessage: 'Swagger' }
});

export default messages;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { store } from './store';
import history from './history';
import setupAxiosInterceptors from './utils/axios-interceptor';
import rootSaga from './store/sagas';
import '../css/index.scss';
import Routes from './routing/routes';
import { MUI_THEME } from './const';
import { requestSession } from './auth/actions';

window.React = React;
const materialTheme = createMuiTheme(MUI_THEME);

store.runSaga(rootSaga);
setupAxiosInterceptors(store.dispatch);
store.dispatch(requestSession());

function render () {
  ReactDOM.render((
    <Provider store={store}>
      <MuiThemeProvider theme={materialTheme}>
        <Router history={history}>
          <Routes />
        </Router>
      </MuiThemeProvider>
    </Provider>
  ), document.getElementById('root'));
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./routing/routes', render);
}

render();

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { isDefined } from 'utils/is-defined';
import { Typography, Button } from '@material-ui/core';

import messages from 'messages';
import { open } from './reducers';

const Toast = ({ message, stackTrace, open, intl }) => {
  return (
    <div className="api-error-toast">
      <Typography variant="body1">{message}</Typography>
      {isDefined(stackTrace) && (
        <Button
          onClick={open}
          className="aet-button"
          data-testid="details-btn"
          size="small"
          color="inherit">
          {intl.formatMessage(messages.errorDetails)}
        </Button>
      )}
    </div>
  );
};

export default flowRight(
  injectIntl,
  connect(
    null,
    (dispatch, { stackTrace }) => ({
      open: () => dispatch(open(stackTrace))
    })
  )
)(Toast);

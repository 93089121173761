import { createReduxActionTypes } from '../../../utils/action-factory';

const ADD_REQUEST_PENDING = 'ADD_REQUEST_PENDING';
const REMOVE_REQUEST_PENDING = 'REMOVE_REQUEST_PENDING';

const actions = createReduxActionTypes('APP_LOADER', [
  ADD_REQUEST_PENDING,
  REMOVE_REQUEST_PENDING
]);

export const addRequestPending = () => ({ type: actions[ADD_REQUEST_PENDING] });
export const removeRequestPending = () => ({ type: actions[REMOVE_REQUEST_PENDING] });

export default function appLoader (state = { requestsPendingCount: 0 }, action) {
  switch (action.type) {
    case actions[ADD_REQUEST_PENDING]:
      return {
        ...state,
        requestsPendingCount: state.requestsPendingCount + 1
      };

    case actions[REMOVE_REQUEST_PENDING]:
      return {
        ...state,
        requestsPendingCount: !state.requestsPendingCount ? 0 : state.requestsPendingCount - 1
      };

    default:
      return state;
  }
}

import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

import { NAV_URLS } from '../../utils/navigation-service';

export function PrivateRoute ({
  component: Component,
  path,
  authenticated = false
}) {
  return (
    <Route
      path={path}
      render={props => authenticated
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: NAV_URLS.LOGIN_PAGE,
            state: {
              from: props.location,
              afterLoginPath: props.location.pathname
            }
          }}/>
      }
    />
  );
};

export default connect(({ auth }) => ({
  authenticated: auth.authenticated
}))(PrivateRoute);

// eslint-disable-next-line
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/mode/xml';
import 'brace/theme/textmate';
import 'brace/mode/javascript';
import 'brace/mode/java';
import 'brace/mode/html';

const OPTIONS = {
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  showLineNumbers: true,
  tabSize: 2
};

export default function Editor ({
  title,
  name,
  onChange,
  onBlur,
  value,
  mode = 'json',
  height = '100%'
}) {
  return (
    <AceEditor
      width="100%"
      height={height}
      placeholder={title}
      mode={mode}
      onBlur={onBlur}
      theme="textmate"
      name={name}
      onChange={onChange}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={value}
      setOptions={OPTIONS}
    />
  );
}

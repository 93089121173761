import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

function AppLoader ({ requestsPendingCount }) {
  return (
    requestsPendingCount !== 0 && <LinearProgress className="common-loader-linear" variant="query" />
  );
}

export default connect(({ appLoader }) => ({
  requestsPendingCount: appLoader.requestsPendingCount
}))(AppLoader);

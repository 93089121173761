import MediaQuery from 'react-responsive';

import TopNavBar from './components/top-nav-bar';
import SideNavBar from './components/side-nav-bar';
import { NAVIGATION_WIDTH_BREAKPOINT } from '../../const';

export default function NavigationBar () {
  return (
    <div className="app-nav">
      <MediaQuery maxWidth={NAVIGATION_WIDTH_BREAKPOINT}>
        <TopNavBar/>
      </MediaQuery>
      <SideNavBar/>
    </div>
  );
};

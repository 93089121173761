import Optional from 'utils/optional';
import { createReduxActionTypes } from '../../utils/action-factory';
import { ROLE_ADMIN } from '../../utils/user-label';

const AUTH_LOG_IN = 'AUTH_LOG_IN';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
const SET_SESSION = 'SET_SESSION';
const SET_INITIALIZED = 'SET_INITIALIZED';

export const actions = createReduxActionTypes('AUTHORIZATION', [
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  SET_SESSION,
  SET_INITIALIZED
]);

// actions
export const setLogOut = () => ({ type: actions[AUTH_LOG_OUT] });
export const setLogIn = () => ({ type: actions[AUTH_LOG_IN] });
export const setSession = data => ({ type: actions[SET_SESSION], data });
export const setInitialized = (value = true) => ({ type:actions[SET_INITIALIZED], value });

// selectors
export const getUserInfo = ({ auth }) => ({ login: auth.login, displayName: `${auth.firstName} ${auth.lastName}` });
export const getRootAccountId = ({ auth }) => auth.accountId;
export const getUserLogin = ({ auth }) => auth.login;
export const isAuthenticated = ({ auth }) => auth.authenticated;

export default function auth (state = {
  authenticated: false,
  isAdmin: false,
  authorities: [],
  initialized: false
}, action) {
  switch (action.type) {
    case actions[SET_INITIALIZED]:
      return {
        ...state,
        initialized: action.value
      };
    case actions[AUTH_LOG_IN]:
      return {
        ...state,
        authenticated: true
      };

    case actions[AUTH_LOG_OUT]:
      return {
        ...state,
        authenticated: false
      };

    case actions[SET_SESSION]:
      return {
        ...state,
        ...action.data,
        isAdmin: Optional(action.data.authorities).or([]).includes(ROLE_ADMIN)
      };

    default:
      return state;
  }
}

import { createReduxActionTypes } from '../../utils/action-factory';

export const SET_LOADING = 'SET_LOADING';

export const actions = createReduxActionTypes('GLOBAL_LOADING', [
  SET_LOADING
]);

// actions
export const setLoading = loading => ({ type: actions[SET_LOADING], loading });

// selectors
export const getLoading = ({ request }) => request.loading;

export default function request (state = {
  loading: false
}, action) {
  switch (action.type) {
    case actions[SET_LOADING]:
      return {
        ...state,
        loading: action.loading
      };

    default:
      return state;
  }
}

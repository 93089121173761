import { Fragment } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from 'messages';

import { Link as RouterLink } from 'react-router-dom';
import { NAV_URLS } from 'utils/navigation-service';
import Optional from 'utils/optional';
import { isDefined } from 'utils/is-defined';

const RenderName = ({ name }) => Optional(name).or('');
const getTranslatedName = message =>
  injectIntl(({ intl }) => intl.formatMessage(message));

const breadcrumbNameMap = {
  [NAV_URLS.HOME]: getTranslatedName(messages.home),
  [NAV_URLS.PRODUCT_OFFERINGS]: getTranslatedName(messages.cpt),
  [NAV_URLS.OFFERS]: getTranslatedName(messages.offers),
  [NAV_URLS.OFFER_DETAILS]: connect(({ offer }) => ({
    name: offer.details.initialValues.name
  }))(RenderName),
  [NAV_URLS.ACCOUNTS]: getTranslatedName(messages.accounts),
  [NAV_URLS.ACCOUNT_DETAILS]: connect(({ accountDetails, order }) => ({
    name: Optional(accountDetails.initialValues.name).or(
      order.details.initialValues.account.name
    )
  }))(RenderName),
  [NAV_URLS.FAV_ACCOUNTS]: getTranslatedName(messages.favAccounts),
  [NAV_URLS.PRODUCT_OFFERING_DETAILS]: connect(
    ({ commercialCatalog }) => ({
      name: commercialCatalog.productOfferingDetails.initialValues.name
    })
  )(RenderName),
  [NAV_URLS.BULLETIN]: getTranslatedName(messages.bulletin),
  [NAV_URLS.PIPELINE]: getTranslatedName(messages.pipeline),
  [NAV_URLS.MY_DELIVERIES]: getTranslatedName(messages.myDeliveries),
  [NAV_URLS.TASKS]: getTranslatedName(messages.tasks),
  [NAV_URLS.MY_TASKS]: getTranslatedName(messages.myTasks),
  [NAV_URLS.MY_ORDERS]: getTranslatedName(messages.myOrders),
  [NAV_URLS.ORDERS]: getTranslatedName(messages.orders),
  [NAV_URLS.ORDER_DETAILS]: connect(({ order }) => ({
    name: order.details.initialValues.name
  }))(RenderName),
  [NAV_URLS.BPMN]: getTranslatedName(messages.bpmn),
  [NAV_URLS.MODELER]: getTranslatedName(messages.modeler),
  [NAV_URLS.DICTIONARIES]: getTranslatedName(messages.dictionaries),
  [NAV_URLS.DICTIONARIES_DETAILS]: connect(({ dictionaryDetails }) => ({
    name: `${Optional(dictionaryDetails.values)
      .map(data => data.id)
      .or(null)}`
  }))(RenderName),
  [NAV_URLS.MODELER_PROCESS_DETAILS]: connect(({ modeler }) => ({
    name: `${Optional(modeler.process.data)
      .map(data => data.id)
      .or(null)}`
  }))(RenderName),
  [NAV_URLS.PROCESSES]: getTranslatedName(messages.processes),
  [NAV_URLS.PROCESS_DEFINITIONS]: getTranslatedName(messages.activeProcesses),
  [NAV_URLS.TEMPLATES]: getTranslatedName(messages.templates),
  [NAV_URLS.PROCESS_DETAILS]: connect(({ processes }) => ({
    name: processes.details.name
  }))(RenderName),
  [NAV_URLS.CATALOGS]: getTranslatedName(messages.catalogs),
  [NAV_URLS.CATALOG_DETAILS]: connect(({ commercialCatalog }) => ({
    name: `${Optional(commercialCatalog.catalogs.details.name).or(null)}`
  }))(RenderName)
};

const Breadcrumb = ({ last, to, value, isEntityLink }) => {
  const toBreadcrumbName = isEntityLink ? to.replace(value, ':id?') : to;
  const RenderVal = breadcrumbNameMap[toBreadcrumbName];
  if (!RenderVal) {
    return null;
  }
  return (
    <Fragment>
      <span className="cb-breadcrumb">
        <RouterLink to={to} data-testid="breadcrumb" className="cb-link">
          <RenderVal />
        </RouterLink>
      </span>
      <span className="cb-breadcrumb"> {last ? '' : '/'} </span>
    </Fragment>
  );
};

export default withRouter(({ location }) => {
  const pathnames = location.pathname.split('/').filter(x => x);
  return (
    <span className="common-breadcrumbs">
      {buildBreadcrumbSegments(pathnames).map(segment => (
        <Breadcrumb key={segment.to} {...segment} />
      ))}
    </span>
  );
});

const buildBreadcrumbSegments = pathnames => {
  return pathnames.map((value, index) => {
    const last = index === pathnames.length - 1;
    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
    const isEntityLink = isDefined(
      breadcrumbNameMap[to.replace(value, ':id?')]
    );
    return { last, to, value, isEntityLink };
  });
};

import { createReduxActionTypes } from 'utils/action-factory';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const actions = createReduxActionTypes('API_ERRORS_DIALOG', [
  OPEN_DIALOG,
  CLOSE_DIALOG
]);

export const open = stackTrace => ({ type: actions[OPEN_DIALOG], stackTrace });
export const close = () => ({ type: actions[CLOSE_DIALOG] });

export const DEFAULT_STATE = {
  isOpen: false
};

export default function apiErrorDialog (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actions[OPEN_DIALOG]:
      return {
        ...state,
        stackTrace: action.stackTrace,
        isOpen: true
      };

    case actions[CLOSE_DIALOG]:
      return {
        ...DEFAULT_STATE
      };

    default:
      return state;
  }
}

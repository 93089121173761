const LANGUAGE_CODE_PATTERN = /^(\w+)(?:-(\w+))?$/;

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = [DEFAULT_LANGUAGE, 'da'];

export function getBrowserLanguage () {
  const browserLanguage = (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

  return getLanguageByLanguageCode(browserLanguage);
}

function getLanguageByLanguageCode (code) {
  const matches = LANGUAGE_CODE_PATTERN.exec(code);
  if (!matches) {
    return DEFAULT_LANGUAGE;
  }

  const [language] = matches.slice(1);
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    return DEFAULT_LANGUAGE;
  }
  return language;
}

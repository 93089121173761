import  { useEffect } from 'react';
import { saveToStorage } from 'utils/local-storage';
import { useLocation } from 'react-router';

export const REDIRECT_URL = 'REDIRECT_URL';
export const LoginRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    saveToStorage(REDIRECT_URL, location.state.from.pathname);
    window.location.reload();
  }, []);

  return null;
};

export default LoginRedirect;
import { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import { KeyboardArrowDown } from '@material-ui/icons';

import { isDefined } from 'utils/is-defined';

class NavBtn extends Component {
  constructor () {
    super();

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
  };

  render = () => {
    const { isOpen } = this.state;
    const {
      label,
      to,
      Icon,
      children = [],
      level = 0,
      intl,
      menuVisibility,
      configKey,
      AdditionalContent = () => null
    } = this.props;
    if (
      isDefined(menuVisibility) &&
      isDefined(menuVisibility[configKey]) &&
      !menuVisibility[configKey]
    ) {
      return null;
    }

    return (
      <div
        className={clsx(
          'ans-nav-btn-container',
          configKey,
          `level-${level}`,
          {
            open: isOpen
          }
        )}>
        <NavLink
          to={to}
          className="app-nav-button"
          onClick={() => {
            !isOpen && this.toggle();
          }}>
          {children.length > 0 ? (
            <KeyboardArrowDown
              className={`ans-nav-arrow ${isOpen ? 'rotate' : ''}`}
              onClick={e => {
                this.toggle();
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          ) : null}
          <Icon className="anb-icon" />
          <span data-testid="app-nav-link">{intl.formatMessage(label)}</span>
          <AdditionalContent />
        </NavLink>
        <Collapse className="ans-nav-btn-nested" in={isOpen}>
          {children.map(child => (
            <NavBtn intl={intl} key={child.to} {...child} level={level + 1} />
          ))}
        </Collapse>
      </div>
    );
  };
}

export default withRouter(
  connect(({ configuration }) => ({
    menuVisibility: configuration.menu
  }))(injectIntl(NavBtn))
);

import { createSagaActionTypes } from '../../../utils/action-factory';

const ADD_DICTIONARY_ENTRIES = 'ADD_DICTIONARY_ENTRIES';

const actions = createSagaActionTypes('DICTIONARIES', [
  ADD_DICTIONARY_ENTRIES
]);

// actions
export const addDictionaryEntries = ({ dict, entries }) => ({ type: actions[ADD_DICTIONARY_ENTRIES], dict, entries });

// selectors
export const dictionariesSelector = ({ dictionaries }) => dictionaries;

export default function dictionaries (state = {}, action) {
  switch (action.type) {
    case actions[ADD_DICTIONARY_ENTRIES]:
      return {
        ...state,
        [action.dict]: action.entries
      };

    default:
      return state;
  }
}

import { isArray } from '../utils/is-defined';

const reduxArray = store => next => action => {
  return isArray(action)
    ? action
      .filter(Boolean)
      .reduce((prevAction, nextAction) => next(nextAction), action[0])
    : next(action);
};

export default reduxArray;

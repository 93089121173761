import axios from 'axios';
import { toast } from 'react-toastify';
import messages from 'messages';

import { isDefined, isStringDefined, isFunction } from '../is-defined';
import translate from '../translate';
import { STATUS_CODES } from '../endpoints';
import Toast from '../../api-error-dialog/toast';
import Optional from 'utils/optional';

const DEFAULT_ERROR_MSG = () => translate(messages.defaultErr);
export const GET = 'get';
export const POST = 'post';
export const DELETE = 'delete';
export const PUT = 'put';

export function request ({
  type = GET,
  url,
  config = {},
  requestConfig,
  successMsg,
  failMsg,
  progressMsg,
  versionConflictMsg
}) {
  let processNotifyId = null;
  let err;
  if (isStringDefined(progressMsg)) {
    processNotifyId = toast.info(progressMsg);
  }
  try {
    return axios[type](url, config, requestConfig)
      .then(response => {
        if (isDefined(successMsg)) {
          const msg = isFunction(successMsg) ? successMsg(response) : successMsg;
          toast.success(msg);
        }
        return response;
      })
      .catch(data => {
        throw handleError(data, failMsg, versionConflictMsg);
      });
  } catch (e) {
    err = e;
    console.warn(e);
    toast.error(DEFAULT_ERROR_MSG());
  }

  if (isDefined(processNotifyId)) {
    toast.dismiss(processNotifyId);
  }
  if (err) throw err;
}

export function handleError (data, failMsg = '', versionConflictMsg = '') {
  if (isDefined(data.response) && data.response.status === STATUS_CODES.CONFLICT) {
    isDefined(data.response.detail) && console.error(data.response.detail);
    toast.error(<Toast message={`${DEFAULT_ERROR_MSG()}: ${data.response.status} ${versionConflictMsg}`}/>);
  } else if (isDefined(data.response)) {
    isDefined(data.response.detail) && console.error(data.response.detail);
    const message = isStringDefined(failMsg) ? failMsg : `${DEFAULT_ERROR_MSG()}: ${data.response.status}`;
    toast.error(<Toast message={message} stackTrace={
      Optional(data.response.data)
        .map(data => data.stackTrace)
        .or(data.response.detail)
    }/>);
  } else if (isDefined(data.request)) {
    toast.error(<Toast message={`${DEFAULT_ERROR_MSG()}: ${translate(messages.noResponse)}`}/>);
  } else {
    toast.error(<Toast message={DEFAULT_ERROR_MSG()}/>);
  }
  return data;
}

import { call, put, delay } from 'redux-saga/effects';
import axios from 'axios';

import { takeLatest } from '../../utils/redux-saga';
import { URLS, STATUS_CODES } from '../../utils/endpoints';
import { createSagaActionTypes } from '../../utils/action-factory';
import { request, POST } from '../../utils/http';

import { setLogIn, setLogOut, setSession, setInitialized } from '../reducers';
import { NAV_URLS, getStatusPageUrl } from 'utils/navigation-service';
import { isDefined, isUndefined } from 'utils/is-defined';
import Optional from 'utils/optional';
import history from '../../history';
import { store } from '../../store';
import { toast } from 'react-toastify';

const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const SESSION_REQUEST = 'SESSION_REQUEST';
const TENANT_ID_QUERY_PARAM = 'tenantId';

const actions = createSagaActionTypes('AUTHORIZATION', [
  LOGOUT_REQUEST,
  SESSION_REQUEST
]);

const ROLE_API = 'ROLE_API';

export const requestLogOut = () => ({ type: actions[LOGOUT_REQUEST] });
export const requestSession = () => ({ type: actions[SESSION_REQUEST] });

export function* logOut () {
  yield put(setLogOut());
  const response = yield call(request, { type: POST, url: URLS.LOGOUT });
  window.location.assign(`${response.data.logoutUrl}?idToken=${response.data.idToken}&redirect_uri=${window.location.origin}/login/oauth2/code/oidc`);
}

export function* getSession () {
  let response = {};
  let isAuthenticatedResponse = {};

  try {
    isAuthenticatedResponse = yield call(request, {
      url: URLS.IS_AUTHENTICATED,
      toastErrors: false,
      errorCallback: data => {
        if (data.response.status === STATUS_CODES.UNAUTHORIZED) {
          window.location.assign(getLoginPage());
        }

        if (data.response.status === STATUS_CODES.FORBIDDEN || data.response.status === STATUS_CODES.INTERNAL_ERROR) {
          store.dispatch(setLogOut());
          store.dispatch(setInitialized());
          history.push(getStatusPageUrl(data.response.status));
        }
      }
    });
  } catch (e) {
    console.log('IS AUTHENTICATED - request error:', e);
    return;
  }

  if (!isAuthenticatedResponse.data.authenticated) {
    window.location.assign(getLoginPage());
    return;
  }

  try {
    response = yield call(request, {
      url: URLS.GET_SESSION,
      toastErrors: false,
      errorCallback: data => {
        if (data.response.status === STATUS_CODES.UNAUTHORIZED) {
          window.location.assign(getLoginPage());
        }

        if (data.response.status === STATUS_CODES.FORBIDDEN || data.response.status === STATUS_CODES.INTERNAL_ERROR) {
          store.dispatch(setLogOut());
          store.dispatch(setInitialized());
          history.push(getStatusPageUrl(data.response.status));
        }
      }
    });
  } catch (e) {
    console.log('GET SESSION - request error:', e);
    return;
  }

  const securityDetails = yield call(getSecurityDetails, getUserTenantId(response.data.tenants));
  if (isUndefined(securityDetails)) {
    window.location.assign(getLoginPage());
    return;
  }

  if (isDefined(securityDetails.authorities) && !securityDetails.authorities.includes(ROLE_API)) {
    yield call(toast.error, `Missing role: ${ROLE_API}`);
    yield delay(2000);
  }

  if (response.status === STATUS_CODES.SUCCESS) {
    yield put(setLogIn());
    yield put(setSession({
      ...response.data,
      ...securityDetails
    }));
  } else {
    window.location.assign(getLoginPage());
  }
  yield put(setInitialized());
}

export function httpRequestSession () {
  return axios.get(URLS.GET_SESSION);
}

function* getSecurityDetails(tenantId){
  let response = {};
  try {
    response = yield call(request, {
      url: URLS.GET_SECURITY_DETAILS,
      config: {
        headers: {
          'X-TenantId': tenantId
        }
      },
      toastErrors: false,
      errorCallback: data => {
        if (data.response.status === STATUS_CODES.UNAUTHORIZED) {
          window.location.assign(getLoginPage());
        }
      }
    });
  } catch (e) {
    console.log('GET security details - request error:', e);
    return;
  }
  return response.data;
}

function getLoginPage() {
  const params = new URLSearchParams(window.location.search);
  params.set('redirect_uri', `${window.location.origin}/login/oauth2/code/oidc`);
  return `${NAV_URLS.LOGIN_PAGE}?${params}`;
}

function* watchLogoutRequest () {
  yield takeLatest(actions[LOGOUT_REQUEST], logOut);
}

function* watchGetSession () {
  yield takeLatest(actions[SESSION_REQUEST], getSession);
}

function getUserTenantId(userTenants = []){
  return Optional(getTenantIdFromQueryParams()).or(userTenants[0]);
}

function getTenantIdFromQueryParams() {
  return new URLSearchParams(window.location.search).get(TENANT_ID_QUERY_PARAM);
}

export default [
  watchLogoutRequest(),
  watchGetSession()
];

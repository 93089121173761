import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import createReduxPromiseListener from 'redux-promise-listener';
import rootReducer from './reducers';
import { setupTranslate } from '../utils/translate';
import reduxArray from './redux-array';

export const reduxPromiseListener = createReduxPromiseListener();

export default function configureStore (initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      sagaMiddleware,
      reduxPromiseListener.middleware,
      reduxArray
    )
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  setupTranslate(store);
  return store;
}

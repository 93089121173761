import { isDefined } from './is-defined';
import { isArray } from 'utils/is-defined';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_ACTIVITI_USER = 'ROLE_ACTIVITI_USER';
export const EXPERIMENTAL_FEATURE = 'EXPERIMENTAL_FEATURE';
export const GROUP_ADMINS = 'GROUP_ADMINS';
export const GROUP_MANAGERS = 'GROUP_MANAGERS';
export const GROUP_TECHNICAL = 'GROUP_TECHNICAL';
export const GROUP_IT = 'GROUP_IT';
export const ROLE_SALES_CASE_MANAGER = 'ROLE_SALES_CASE_MANAGER';

export const DEFAULT_LABEL = '';

export default function getUserLabel (authorities) {
  let result = DEFAULT_LABEL;
  if (!isDefined(authorities)) {
    return result;
  }

  if (authorities.includes(ROLE_ADMIN)) {
    result = ROLE_ADMIN;
  } else if (authorities.includes(GROUP_ADMINS)) {
    result = GROUP_ADMINS;
  } else if (authorities.includes(GROUP_MANAGERS)) {
    result = GROUP_MANAGERS;
  } else if (authorities.includes(GROUP_TECHNICAL)) {
    result = GROUP_TECHNICAL;
  } else if (authorities.includes(GROUP_IT)) {
    result = GROUP_IT;
  } else if (authorities.includes(ROLE_SALES_CASE_MANAGER)) {
    result = ROLE_SALES_CASE_MANAGER;
  } else if (authorities.includes(ROLE_USER)) {
    result = ROLE_USER;
  } else if (authorities.includes(ROLE_ACTIVITI_USER)) {
    result = ROLE_ACTIVITI_USER;
  }

  if (authorities.includes(EXPERIMENTAL_FEATURE)) {
    result = `${result} ${EXPERIMENTAL_FEATURE}`;
  }

  if (result === DEFAULT_LABEL) {
    if (isArray(authorities)) {
      return authorities.join(" ");
    } else {
      return authorities;
    }
  }

  return result;
}

import { combineReducers } from 'redux';

import auth, { actions as authActions, AUTH_LOG_OUT } from '../auth/reducers';
import request from '../request/reducers';
import appLoader from '../app/app-loader/reducers';
import navigationBar from '../app/navigation-bar/reducers';
import { promiseActionReducer as promiseAction } from 'common/promise-actions';
import apiErrorDialog from '../api-error-dialog/reducers';
import dictionaries from '../dictionaries/with-dictionary/reducers';

export const reducers = {
  auth,
  request,
  appLoader,
  navigationBar, 
  promiseAction,
  apiErrorDialog,
  dictionaries,
  configuration: (state = {
    menuVisibility: true
  }) => state
};

const combinedReducers = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === authActions[AUTH_LOG_OUT]) {
    state = void 0;
  }

  return combinedReducers(state, action);
};

export default rootReducer;

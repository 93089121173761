import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';

import { close } from './reducers';
import messages from 'messages';
import JsonEditor from 'common/forms/mui/json-field/editor';

const ApiErrorDialog = ({ intl, isOpen, close, stackTrace = '' }) => {
  return (
    <Dialog open={isOpen} onBackdropClick={close} fullWidth>
      <DialogTitle>{intl.formatMessage(messages.errorDetails)}</DialogTitle>
      <DialogContent>
        <JsonEditor height="500px" mode="java" value={stackTrace} />
      </DialogContent>
      <DialogActions>
        <Button data-testid="close-btn" onClick={close}>
          {intl.formatMessage(messages.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default flowRight(
  injectIntl,
  connect(
    ({ apiErrorDialog: { isOpen, stackTrace } }) => ({
      isOpen,
      stackTrace
    }),
    {
      close
    }
  )
)(ApiErrorDialog);

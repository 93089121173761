import { call, put, select } from 'redux-saga/effects';

import { request } from '../../../utils/http/request';
import { isDefined } from '../../../utils/is-defined';
import { takeEvery } from '../../../utils/redux-saga';
import { getDictionaryEntries } from '../../../utils/endpoints';
import { createSagaActionTypes } from '../../../utils/action-factory';

import { addDictionaryEntries, dictionariesSelector } from '../reducers';

const DICTIONARY_REQUEST = 'DICTIONARY_REQUEST';
const actions = createSagaActionTypes('DICTIONARIES', [
  DICTIONARY_REQUEST
]);

export const requestDictionary = (dict, mapEntry) => ({ type: actions[DICTIONARY_REQUEST], dict, mapEntry });

export function* getDictionary ({ dict, mapEntry }) {
  const dictionariesState = yield select(dictionariesSelector);
  const isDictionaryFetched = isDefined(dictionariesState[dict]);

  if (isDictionaryFetched) {
    return dictionariesState[dict];
  } else {
    const { data } = yield call(request, { url: getDictionaryEntries(dict) });
    const dictionary = transformDictionary(data, mapEntry);
    yield put(addDictionaryEntries({ dict, entries: dictionary }));
    return dictionary;
  }
}

export function transformDictionary (data, mapEntry = v => v) {
  return data.reduce((dict, entry) => {
    dict[entry.id] = {
      ...entry,
      value: mapEntry(entry.value)
    };
    return dict;
  }, {});
}

function* watchDictionaryRequest () {
  yield takeEvery(actions[DICTIONARY_REQUEST], getDictionary);
}

export default watchDictionaryRequest();

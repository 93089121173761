import { Inbox } from '@material-ui/icons';
import messages from 'messages';

import { NAV_URLS } from '../../../utils/navigation-service';

const SWAGGER_KEY = 'swagerVisible';

export const NAVIGATION_CONFIG = [{
  label: messages.swagger,
  configKey: SWAGGER_KEY,
  to: NAV_URLS.SWAGGER,
  Icon: Inbox
}];

export const NAV_URLS = {
  HOME: '/home',
  SWAGGER: '/home/swagger',
  STATUS_PAGE: '/home/status/:code',
  LOGIN_PAGE: '/oauth2/authorization/oidc',
  ACTIVATION: '/activate',
  RESET_PASSWORD: '/reset/finish',
  INIT_RESET_PASSWORD: '/reset/init'
};

export const getStatusPageUrl = code => NAV_URLS.STATUS_PAGE.replace(':code', code);

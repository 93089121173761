import { connect } from 'react-redux';

import Avatar from '../../../common/avatar/avatar';
import getUserLabel from '../../../utils/user-label';
import Optional from 'utils/optional';

function User ({ firstName, lastName, imageUrl, authorities }) {
  return (
    <div className="ans-user">
      <Avatar
        firstName={firstName}
        lastName={lastName}
        imageUrl={imageUrl}/>
      <div className="asu-details">
        <p className="asu-d-name">{`${firstName} ${lastName}`}</p>
        <p className="asu-d-role">{getUserLabel(authorities)}</p>
      </div>
    </div>
  );
}

export default connect(({ auth }) => ({
  firstName: auth.firstName,
  lastName: auth.lastName,
  imageUrl: auth.imageUrl,
  authorities: Optional(auth.authorities).or([])
}))(User);

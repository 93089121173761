import { connect } from 'react-redux';

import MenuIcon from '../../../../assets/menu.svg';
import CloseIcon from '../../../../assets/close.svg';

import { toggle } from '../reducers';

function TopNavBar ({ on, toggle }) {
  return (
    <div className="an-topnavbar">
      <div className="ant-menuicon" onClick={toggle}>
        {on ? <CloseIcon/> : <MenuIcon/> }
      </div>
    </div>
  );
}

export default connect(({ navigationBar }) => ({
  on: navigationBar.on
}), { toggle })(TopNavBar);

import messages from 'messages';
import { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { KeyboardArrowRight } from '@material-ui/icons';
import { CSSTransition } from 'react-transition-group';
import elementResizeDetectorMaker from 'element-resize-detector';

import OffIcon from '../../../../assets/off.svg';
import IconBtn from '../../../common/buttons/icon-btn';
import { isDefined } from '../../../utils/is-defined';
import Optional from '../../../utils/optional';
import { hasExperimentalRole } from '../../../common/experimental-feature/selectors';

import User from './user';
import { open, toggleMenuSize } from '../reducers';
import NavBtn from './nav-btn';
import { requestLogOut } from '../../../auth/actions';
import { NAVIGATION_CONFIG } from '../const';
import { NAVIGATION_WIDTH_BREAKPOINT } from '../../../const';

const MOCKED_APP_INFO = 'BPM Micro Software v0.1';

class SideNavBar extends Component {
  navRoutes = [];

  constructor (props) {
    super(props);
    let routes = Optional(props.routes).or(NAVIGATION_CONFIG);

    if (!props.isAdmin) {
      routes = this.filterAdminRoutes(routes);
    }

    if (!props.hasExperimentalRole) {
      routes = this.filterExperimentalRoutes(routes);
    }

    this.navRoutes = routes;
  }

  componentDidMount () {
    this.attachListener();
  }

  filterAdminRoutes (routes) {
    return this.filter(routes, route => !route.adminRoute);
  }

  filterExperimentalRoutes (routes) {
    return this.filter(routes, route => !route.experimental);
  }

  filter (routes, filterFn) {
    return routes.filter(route => {
      if (isDefined(route.children)) {
        route.children = this.filter(route.children, filterFn);
      }
      return filterFn(route);
    });
  }

  attachListener () {
    const appNode = document.querySelector('.app');
    if (isDefined(appNode)) {
      elementResizeDetectorMaker({ strategy: 'scroll' })
        .listenTo(appNode, () => this.onAppNodeResize());
    }
  }

  onAppNodeResize () {
    const { on, open } = this.props;
    if (!on && window.innerWidth >= NAVIGATION_WIDTH_BREAKPOINT) {
      open();
    }
  }

  render () {
    const { on = false, requestLogOut, toggleMenuSize, intl } = this.props;
    return (
      <CSSTransition in={on} mountOnEnter unmountOnExit timeout={300} classNames="sidebar">
        <aside className="an-sidebar" data-testid="an-sidebar">
          <KeyboardArrowRight className="an-arrow" onClick={toggleMenuSize}/>
          <nav className="ans-nav">
            {this.navRoutes.map(link => <NavBtn key={link.to} {...link} />)}
          </nav>
          <User />
          <div className="ans-bottomnav">
            <IconBtn label={intl.formatMessage(messages.logOut)} Icon={OffIcon} onClick={requestLogOut} />
          </div>
          <p className="ans-app-info">{MOCKED_APP_INFO}</p>
        </aside>
      </CSSTransition>
    );
  }
}

export default flowRight(
  withRouter,
  injectIntl,
  connect(state => ({
    isAdmin: state.auth.isAdmin,
    hasExperimentalRole: hasExperimentalRole(state),
    on: state.navigationBar.on
  }), {
    open,
    requestLogOut,
    toggleMenuSize
  })
)(SideNavBar);
